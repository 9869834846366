import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import JSZip from "jszip";
import { useParams } from "react-router-dom";
import Logo from "./../assets/img/logo.svg";
import RiveC from "@rive-app/react-canvas";
import { useRive, Layout } from "@rive-app/react-canvas";

//import { RiveComponent } from 'rive-react';

const baseUrl = process.env.REACT_APP_API_URL;

const DownloadSection = () => {
  const [loading, setLoading] = useState(true);
  const abortRef = React.useRef(false);
  const [percentageDownload, setPercentageDownload] = useState(0);
  const [message, setMessage] = useState(
    "Aguarde, estamos capturando os arquivos :)"
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const riveRef = useRef(null);
  let Id = null;
  let PorcentagemPorItem = 0;
  let letPercentageDownload = 0;
  let Arquivos = [];
  const { parametro } = useParams();
  let ignore = false;

  const obterArquivosDownload = async () => {
    try {
      ignore = true;
      if (parametro === null) return;
      Id = parametro;

      setLoading(true);

      const downloadUrl = `${baseUrl}/package-download/${Id}`;
      console.log(downloadUrl);
      const response = await axios.get(downloadUrl);

      if (response.status === 200) {
        Arquivos = response.data || {};
        PorcentagemPorItem = 100 / Arquivos.files.length;
        //setPercentageItem(PorcentagemPorItem)

        //await delay(2000);
        setMessage("Quase lá, estamos processando seu download ;)");
        //await delay(1000);

        await criarZip(Arquivos, Arquivos.tipoPackage);

        if (percentageDownload < 100) {
          setPercentageDownload(100);
        }

        setLoading(false);
      } else {
        setErrorMessage(
          "Não foi possível obter os dados do package no servidor de imagens, verifique se a url de acesso está correta."
        );

        setLoading(false);
      }

      setPercentageDownload(0);
    } catch (error) {
      setErrorMessage("Houve um problema ao se conectar com o servidor.");
      setLoading(false);
      throw error;
    }
  };

  async function criarZip(sourceFiles, tipoPacote, porGenero = null) {
    const zip = new JSZip();

    for (const file of sourceFiles.files) {
      if (abortRef.current) {
        break;
      }

      let zipItem;

      const pastaLimpa = file.folder
        ? file.folder.split("/").map(stringSemCaracterEspecial).join("/")
        : "";

      // 2 é tipo composite
      let fileContent;

      const response = await axios.get(file.path, {
        responseType: "arraybuffer",
      });
      fileContent = response.data;
      if (tipoPacote === 2) {
        if (
          porGenero != null &&
          (porGenero == true || porGenero.toLowerCase() === "sim")
        ) {
          zipItem = zip.file(
            `${pastaLimpa}/${file.name}.${file.extension}`,
            fileContent
          );
        } else {
          zipItem = zip.file(`${file.name}.${file.extension}`, fileContent);
        }
      } else {
        zipItem = zip.file(
          `${pastaLimpa}/${file.name}.${file.extension}`,
          fileContent
        );
      }
      //await delay(500);
      letPercentageDownload = letPercentageDownload + PorcentagemPorItem;
      setPercentageDownload(letPercentageDownload.toFixed(2));
    }

    if (abortRef.current) {
      setPercentageDownload(0);
      return;
    }

    sourceFiles.nomeZip = sourceFiles.nomeZip
      .replace("/", " ")
      .replace("-", " ")
      .replace(".", " ")
      .replace(",", " ")
      .replace("\\", " ");
    sourceFiles.nomeZip = stringSemCaracterEspecial(sourceFiles.nomeZip);

    //rive.source = '/download-emodel-end.riv'

    const zipName = `${sourceFiles.nomeZip}.zip`;

    // Gere o arquivo ZIP
    zip.generateAsync({ type: "blob" }).then((conteudo) => {
      // Crie um link para download
      const linkDownload = document.createElement("a");
      linkDownload.href = URL.createObjectURL(conteudo);
      linkDownload.download = zipName;
      linkDownload.click();

      // Após o download, navegar para a página anterior
      // window.history.back();
    });
  }

  function stringSemCaracterEspecial(str) {
    const mapaAcentos = {
      Á: "A",
      À: "A",
      Ã: "A",
      Â: "A",
      Ä: "A",
      á: "a",
      à: "a",
      ã: "a",
      â: "a",
      ä: "a",
      É: "E",
      È: "E",
      Ê: "E",
      Ë: "E",
      é: "e",
      è: "e",
      ê: "e",
      ë: "e",
      Í: "I",
      Ì: "I",
      Î: "I",
      Ï: "I",
      í: "i",
      ì: "i",
      î: "i",
      ï: "i",
      Ó: "O",
      Ò: "O",
      Õ: "O",
      Ô: "O",
      Ö: "O",
      ó: "o",
      ò: "o",
      õ: "o",
      ô: "o",
      ö: "o",
      Ú: "U",
      Ù: "U",
      Û: "U",
      Ü: "U",
      ú: "u",
      ù: "u",
      û: "u",
      ü: "u",
      Ç: "C",
      ç: "c",
      Ñ: "N",
      ñ: "n",
      Ý: "Y",
      ý: "y",
      ÿ: "y",
    };

    // Substitui as letras com acento pelo proprio caracter sem acento
    const textoSemAcento = str
      .split("")
      .map((char) => mapaAcentos[char] || char)
      .join("");

    // Remove caracteres especiais, mantendo letras acentuadas, números e espaços
    let textoLimpo = textoSemAcento.replace(/[^a-zA-Z0-9 ]/g, "");
    // Remove espaços duplicados e espaços no início e no fim
    return textoLimpo.trim().replace(/\s+/g, " ");
  }

  useEffect(() => {
    if (!ignore) obterArquivosDownload();
  }, []);

  useEffect(() => {
    const animationInstance = riveRef.current?.play();
    //setIsPlaying(true);

    return () => {
      animationInstance?.pause();
    };
  }, []);

  const [iniciado, setIniciado] = useState(true);

  const { rive, RiveComponent } = useRive({
    src: "/download-emodel.riv",
    autoplay: true,
    layout: Layout.Fit,
    onPlay: () => {
      setIniciado(true);
    },
    onStop: () => {
      setIniciado(false);
    },
  });

  useEffect(() => {
    if (rive && !rive.isPlaying) {
      rive.reset();
      rive.play();
    }
  }, [rive, iniciado]);

  return (
    <section style={{ marginBottom: "30px" }}>
      <img
        src={Logo}
        className="mb-4"
        style={{ paddingBottom: "80px", paddingTop: "80px" }}
      />

      {loading && (
        <div>
          <RiveComponent style={{ height: "450px" }} />
          <h5>
            {message}
            <span style={{ marginLeft: "10px" }}>{percentageDownload}%...</span>
          </h5>
          <div>
            <button
              onClick={() => (abortRef.current = true)}
              style={{
                color: "#fff",
                borderRadius: "25px",
                backgroundColor: "#ff9300",
                border: "1px solid #ff9300",
                padding: "8px 24px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#ffab00")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff9300")}
            >
              Clique aqui para cancelar o download
            </button>
          </div>
        </div>
      )}

      {/* {loading && (
                <div>
                    <h5>{message}</h5>
                    <div className="spinner-border text-warning" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )} */}

      {errorMessage && (
        <div>
          <h5>{errorMessage}...</h5>
          <button
            onClick={obterArquivosDownload}
            className="btn btn-outline-success"
          >
            Tentar Novamente
          </button>
        </div>
      )}

      {!loading && !errorMessage && (
        <div>
          {/* <RiveComponent /> */}
          <RiveC
            src={"/download-emodel-end.riv"}
            style={{ height: "450px" }}
            play
          />
          {/* <h5>Download Finalizado!</h5> */}
          <button
            onClick={() => window.history.back()}
            style={{
              color: "#fff",
              borderRadius: "25px",
              backgroundColor: "#ff9300",
              border: "1px solid #ff9300",
              padding: "8px 24px",
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#ffab00")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff9300")}
          >
            Voltar
          </button>
        </div>
      )}
    </section>
  );
};

export default DownloadSection;
